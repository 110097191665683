import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataReferenceConfig, DataReferencePipe, ViewComponent, ViewConfig, ViewSlotDirective, viewComponentInputs, viewRegistry } from '@npm-libs/ng-templater';
import { AvatarInitialsPipe } from './avatar-initials.pipe';

const name = 'avatar';
type Name = typeof name;

export type AvatarViewProps = {
  text: DataReferenceConfig, 
  color?: DataReferenceConfig,
  size?: string
};
export type AvatarViewConfig = ViewConfig<Name, AvatarViewProps>;

@Component({
  standalone: true,
  selector: `rcg-templater-view--${name}`,
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: [...viewComponentInputs],
  imports: [CommonModule, ViewSlotDirective, DataReferencePipe, AvatarInitialsPipe],
  templateUrl: './avatar.view.component.html',
  styleUrls: ['./avatar.view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarViewComponent extends ViewComponent<Name, AvatarViewProps, AvatarViewConfig> {}

viewRegistry[name] = {
  component: AvatarViewComponent,
};
